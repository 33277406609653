import ClearIcon from "@mui/icons-material/Clear";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AccessRequestModal from "../AccessRequestModal";

const DocumentsSection = (props) => {
  const theme = localStorage.getItem("theme");
  const { data, handleDrawerClose, triggerIndex, publicUrlId } = props;
  const scrollToRef = useRef(null);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [activeDocument, setActiveDocument] = useState(null);

  useEffect(() => {
    // Scroll to the specified ref when the drawer is opened
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    if (triggerIndex !== null) {
      const triggeredItem = document.getElementById(
        `dcoumentItem-${triggerIndex}`
      );
      if (triggeredItem) {
        triggeredItem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [scrollToRef, triggerIndex]);

  const truncateText = (text, startLength, endLength) => {
    if (text.length <= startLength + endLength) {
      return text;
    }
    const startText = text.slice(0, startLength);
    const endText = text.slice(-endLength);
    return `${startText}...${endText}`;
  };

  return (
    <>
      <Container
        maxWidth={false}
        className={theme === "Light" ? "light-mode-class" : "dark-mode-class"}
        style={{ paddingBottom: "18px" }}
      >
        <Box
          sx={{
            mt: 2,
            mb: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ mt: 1, mb: 1 }}>
            <span>Documents</span>
          </Typography>
          <ClearIcon onClick={handleDrawerClose} sx={{ cursor: "pointer" }} />
        </Box>
        <Grid container spacing={3} sx={{ padding: 0 }}>
          {data.map((docItem, index) => (
            <Grid item xs={12} md={12}>
              <Paper
                elevation={3}
                id={`dcoumentItem-${index}`}
                sx={{
                  padding: "12px",
                }}
                className={triggerIndex === index ? "active-border-line" : ""}
                key={docItem._id}
                ref={index === triggerIndex ? scrollToRef : null}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="ISO-private">
                      <h3>{docItem.category}</h3>
                      <p>
                        {docItem.type === "private" ? (
                          <>
                            <LockOutlinedIcon /> {docItem.type}
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill={theme === "Light" ? "#000" : "#fff"}
                              viewBox="0 0 24 24"
                            >
                              <path d="M16 3.25C14.7402 3.25 13.532 3.75045 12.6412 4.64124C11.7504 5.53204 11.25 6.74022 11.25 8V10.25H6C5.27065 10.25 4.57118 10.5397 4.05546 11.0555C3.53973 11.5712 3.25 12.2707 3.25 13V18C3.25 18.7293 3.53973 19.4288 4.05546 19.9445C4.57118 20.4603 5.27065 20.75 6 20.75H13C13.7293 20.75 14.4288 20.4603 14.9445 19.9445C15.4603 19.4288 15.75 18.7293 15.75 18V13C15.75 12.2707 15.4603 11.5712 14.9445 11.0555C14.4288 10.5397 13.7293 10.25 13 10.25H12.75V8C12.75 7.13805 13.0924 6.3114 13.7019 5.7019C14.3114 5.09241 15.138 4.75 16 4.75C16.862 4.75 17.6886 5.09241 18.2981 5.7019C18.9076 6.3114 19.25 7.13805 19.25 8C19.25 8.19891 19.329 8.38968 19.4697 8.53033C19.6103 8.67098 19.8011 8.75 20 8.75C20.1989 8.75 20.3897 8.67098 20.5303 8.53033C20.671 8.38968 20.75 8.19891 20.75 8C20.75 6.74022 20.2496 5.53204 19.3588 4.64124C18.468 3.75045 17.2598 3.25 16 3.25ZM14.25 13V18C14.25 18.3315 14.1183 18.6495 13.8839 18.8839C13.6495 19.1183 13.3315 19.25 13 19.25H6C5.66848 19.25 5.35054 19.1183 5.11612 18.8839C4.8817 18.6495 4.75 18.3315 4.75 18V13C4.75 12.6685 4.8817 12.3505 5.11612 12.1161C5.35054 11.8817 5.66848 11.75 6 11.75H13C13.3315 11.75 13.6495 11.8817 13.8839 12.1161C14.1183 12.3505 14.25 12.6685 14.25 13Z" />
                            </svg>{" "}
                            {docItem.type}
                          </>
                        )}
                      </p>
                    </div>

                    <div>
                      {docItem?.logo === "" ||
                      docItem?.logo === null ||
                      docItem?.logo === undefined ||
                      docItem?.logo === "undefined" ? (
                        <p className="discription-text">Logo Not Available</p>
                      ) : (
                        <div>
                          <img
                            className="sidebar-logos"
                            src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_DOCUMENTS_LOGO}/${docItem?.logo}`}
                            alt="logo"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="discription-text">
                      <strong>Title:</strong> {docItem.title}
                    </p>
                    <p className="discription-text">
                      <strong>Description:</strong> {docItem.description}
                    </p>
                  </div>

                  {docItem.type === "public" ? (
                    <div className="documents">
                      <div
                        style={{
                          padding: "6px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill={theme === "Light" ? "#000" : "#fff"}
                          width={20}
                          height={20}
                        >
                          <path d="M13 12H16L12 16L8 12H11V8H13V12ZM15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918Z"></path>
                        </svg>
                        <span
                          style={{
                            marginLeft: "6px",
                            wordBreak: "break-all",
                          }}
                        >
                          {truncateText(docItem.document, 20, 20)}
                        </span>
                      </div>
                      <div
                        style={{
                          padding: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <a
                          href={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_DOCUMENTS_DOCUMENT}/${docItem?.document}`}
                          download={true}
                          target="_blank"
                          className="document-downlaod"
                        >
                          <Button
                            variant="contained"
                            className="addnew export-btn downlod-btn"
                            // onClick={handleDocumentDownload}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              width={20}
                              height={20}
                              style={{ marginRight: "5px" }}
                            >
                              <path d="M3 19H21V21H3V19ZM13 9H20L12 17L4 9H11V1H13V9Z"></path>
                            </svg>
                            Download
                          </Button>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Button
                        variant="contained"
                        className="addnew export-btn downlod-btn"
                        onClick={() => {
                          setActiveDocument(docItem._id);
                          setShowRequestModal(true);
                        }}
                      >
                        Request Access
                      </Button>
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
      <AccessRequestModal
        open={showRequestModal}
        handleClose={() => setShowRequestModal(false)}
        publicUrlId={publicUrlId}
        fileId={activeDocument}
        fileType="document"
      />
    </>
  );
};

export default DocumentsSection;
