import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
// import PostRequest from '../apiConnections/postRequest';
import { FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useSnackbar } from "notistack";
import Loader from "../../Loader";

export default function EditSubdomain({
  handleClose,
  open,
  data,
  setSubdomainEdited,
  isExternal,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [showLoader, setShowLoader] = useState(false);
  const [subdomain, setSubdomain] = useState(
    isExternal === true ? "" : new URL(data).hostname
  );
  
  const dns = process.env.REACT_APP_DNS;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "750",
    bgcolor: "background.paper",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "15px",
    p: 4,
  };

  const validateURL = (url) => {
    // Regular expression to match the URL format
    const urlRegex =
      /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9-]{1,63})\.([a-zA-Z0-9]{2,6})(?:\/\S*)?$/;

    // Check if the URL matches the regex pattern
    const isValidURL = urlRegex.test(url);

    if (isValidURL) {
      // Split the URL into subdomain, domain, and TLD parts
      const [, subdomain, domain, tld] = url.match(urlRegex);

      // Check if subdomain, domain, and TLD meet the criteria
      if (subdomain && domain && tld) {
        // Check if the URL contains http or https protocol
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
          return true;
        } else {
          return false; // Reject if the URL contains http or https protocol
        }
      } else {
        return false; // Reject if any part of the URL is missing
      }
    } else {
      return false; // Reject if the URL format doesn't match
    }
  };

  const submitData = () => {
    if (!validateURL(subdomain)) {
      enqueueSnackbar("Invalid URL format", { variant: "error" });
      return;
    }

    const disallowedURLs = [
      "uat.genesisplatform.co",
      "staging.genesisplatform.co",
      "gsc.genesisplatform.co",
    ];

    if (disallowedURLs.includes(subdomain)) {
      enqueueSnackbar("This URL is alreday taken.", { variant: "error" });
      return;
    }

    setSubdomainEdited(subdomain);
  };

  return (
    <div>
      <Loader show={showLoader} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
        }
      >
        <Box sx={style}>
          <Box>
            <Typography>Edit URL</Typography>

            <FormControl
              sx={{ marginTop: 5, marginBottom: 2, width: "77ch" }}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-url">
                Enter URL
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-url"
                type="subdomain"
                value={subdomain}
                onChange={(e) => setSubdomain(e.target.value)} // Update this line
                fullWidth
                label="Public Page URL"
              />
            </FormControl>
          </Box>
          <Grid item xs={12} md={4} lg={4} className="domain-manual">
            <Typography className="fontSize">
              <span>Instruction : </span>
            </Typography>
            <ul>
              <li>Log in to your domain registrar's website.</li>
              <li>Find the DNS management section.</li>
              <li>
                Enter the subdomain and the IP address {dns && dns } {""}.
              </li>
              <li>Save your changes.</li>
              <li>Wait for DNS propagation (usually takes some time).</li>
              <li>Test the subdomain by accessing it in a browser.</li>
              <li>Ensure the external server is properly configured.</li>
              <li>Verify that the subdomain is working as expected.</li>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            sx={{ mt: 5 }}
            className="center-vendor"
          >
            <Button
              variant="contained"
              className="click-to-begin m-l model-btns cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="click-to-begin m-l model-btns cancel-btn"
              onClick={submitData}
            >
              Submit
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
