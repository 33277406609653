import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import "../../pages/css/connectProfile.css";
import EditSubdomain from "./EditSubdomain";

const ConnectPublicURL = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(false);
  const [publicURL, setPublicURL] = useState("");
  const [isExternal, setIsExternal] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [ipCopied, setIpCopied] = useState(false);
  const [editSubdomainModal, setEditSubdomainModal] = useState(false);
  const [subDomainEdited, setSubDomainEdited] = useState('');
  const [subDomainConnected, setSubDomainConnected] = useState(false);

  useEffect(() => {
    ConnectPublicURL();
  }, [subDomainEdited, isExternal]);


  const ConnectPublicURL = async () => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        const requestBody = subDomainEdited
          ? { publicSubDomain: subDomainEdited }
          : {};
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONNECT_THIRD_PARTY_PUBLIC_URL}`,
          requestBody
        )
          .then((res) => {
            if ([200, 201].includes(res?.data?.code)) {
              let publicDomain = res?.data?.data.publicUrlId ?? "";
              if (!publicDomain.includes(".")) {
                setIsExternal(true);
                publicDomain = `${window.location.protocol}//${window.location.host}/public-page/${publicDomain}`;
              } else {
                publicDomain = `${window.location.protocol}//${publicDomain}`;
                setSubDomainConnected(true)
              }
              if(res.data.code === 201){
                enqueueSnackbar(`${res.data.message}`, { variant: "success" });
              };
              setPublicURL(publicDomain);
              setShowLoader(false);
            } else {
              setShowLoader(false);
            }
            setEditSubdomainModal(false);
            setSubDomainEdited('');
          })
          .catch((err) => {
            console.log(err)
            setShowLoader(false);
            enqueueSnackbar(`${err.message}`, { variant: "error" });
          });
        setEditSubdomainModal(false);
        setSubDomainEdited('');
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false);
      setEditSubdomainModal(false);
      setSubDomainEdited('');

    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${publicURL}`).then(() => {
      setTextCopied(true);

      // Set textCopied to false after 3 seconds
      setTimeout(() => {
        setTextCopied(false);
      }, 2000);
    });
  };

  const handleIPCopy = () => {
    const textToCopy = `${publicURL}`; // Replace 'Text to copy' with your desired text
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIpCopied(true);
        setTimeout(() => setIpCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => console.log("Error copying text: ", error));
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
          <span className="heading-main ml25">Public URL</span>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Card
              sx={{ mb: 1 }}
              className="card-public-url"
              style={{ height: "100%" }}
            >
              <div>
                <FormControl
                  sx={{ marginTop: 20, width: "77ch" }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-url">
                    Public Page URL
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-url"
                    type="text"
                    value={`${publicURL}`}
                    fullWidth
                    readOnly={true}
                    endAdornment={
                      <InputAdornment position="end" sx={{ paddingRight: 1.8 }}>
                        <IconButton
                          aria-label="toggle url visibility"
                          onClick={handleCopy}
                          edge="end"
                          sx={{ padding: "14px" }}
                        >
                          {textCopied ? (
                            <Tooltip title="Copied" placement="top">
                              <CheckCircleIcon />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Copy" placement="top">
                              <ContentCopyIcon />
                            </Tooltip>
                          )}
                        </IconButton>
                        {subDomainConnected && (
                          <IconButton
                            aria-label="toggle url visibility"
                            onClick={() => setEditSubdomainModal(true)}
                            edge="end"
                            sx={{ padding: "14px" }}
                          >
                            <Tooltip title="Edit URL" placement="right">
                              <ModeEditOutlineIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      </InputAdornment>
                    }
                    label="Public Page URL"
                  />
                </FormControl>
                <div className="domain-manual">
                  {subDomainConnected ? (
                    <>
                      <Typography className="fontSize">
                        <span>Instruction : </span>
                      </Typography>
                      <ul>
                        <li>Log in to your domain registrar's website.</li>
                        <li>Find the DNS management section.</li>
                        <li>
                          Enter the subdomain and the IP address{" "}
                          {ipCopied ? (
                            <Tooltip title="Copied" placement="top">
                              <span className="ip-text" onClick={handleIPCopy}>
                                {process.env.REACT_APP_DNS}
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Copy" placement="top">
                              <span className="ip-text" onClick={handleIPCopy}>
                              {process.env.REACT_APP_DNS}
                              </span>
                            </Tooltip>
                          )}
                          {""} (uat).
                        </li>
                        <li>Save your changes.</li>
                        <li>
                          Wait for DNS propagation (usually takes some time).
                        </li>
                        <li>
                          Test the subdomain by accessing it in a browser.
                        </li>
                        <li>
                          Ensure the external server is properly configured.
                        </li>
                        <li>
                          Verify that the subdomain is working as expected.
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      Do you want to connect this profile page to your own
                      website ? &nbsp;
                      <button
                        onClick={() => setEditSubdomainModal(true)}
                        style={{
                          border: "none",
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#1976d2",
                          background: "none",
                          padding: "0",
                          margin: "0",
                          outline: "none",
                          transition: "color 0.3s ease",
                          fontWeight: 600,
                          fontSize: "1rem",
                          letterSpacing: '1.5px'
                        }}
                        onMouseOver={(e) => {
                          e.target.style.textDecoration = "underline";
                          e.target.style.color = "#1976d2";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.textDecoration = "none";
                          e.target.style.color = "#1976d2";
                        }}
                      >
                        {" "}
                        Connect Domain
                      </button>
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "25px",
                  }}
                >
                  <Button
                    variant="contained"
                    className="visit-link"
                    onClick={() => {
                      window.open(`${publicURL}`, "_blank");
                    }}
                  >
                    Visit Page
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {editSubdomainModal ? (
        <EditSubdomain
          handleClose={() => setEditSubdomainModal(false)}
          open={editSubdomainModal}
          data={publicURL}
          setSubdomainEdited={setSubDomainEdited}
          isExternal={isExternal}
        />
      ) : null}
    </>
  );
};

export default ConnectPublicURL;
