import {
  Button,
  Card,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import QuestionnaireLogo from '../../assets/questionnaire-image.png';
const AddQuestionnaire = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const myObject = location.state ? location.state.data : null;
  const tabIndex = location.state ? location.state.tabIndex : 0;
  const [questionnaireId, setQuestionnaireId] = useState(
    myObject ? myObject._id : null
  );
  const [showLoader, setShowLoader] = useState(false);
  const [complianceType, setComplianceType] = useState(["Others"]);
  const [adminComplianceType, setAdminComplianceType] = useState([]);

  const [data, setData] = useState({
    type: "",
    category: "",
    description: "",
    logo: "",
    document: "",
  });
  
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    getAdminComplianceType();
    if (questionnaireId && myObject) {
      const { issueDate, expiryDate, ...rest } = myObject;

      setData({
        ...rest,
        issueDate: moment(issueDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        expiryDate: moment(expiryDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      });
    }
  }, [questionnaireId, myObject]);

  const handleChange = (e) => {
    let event = e.target;
    if (event.name === "logo" || event.name === "document") {
      setData({ ...data, [event.name]: event.files[0] });
    } else if (event.name === "category") {
      if (event.value !== "Others") {
        setData({
          ...data,
          category: event.value,
          logo: adminComplianceType.find((item) => item.name == event.value)
            ?.logo,
        });
      } else {
        setData({ ...data, category: event.value, logo: "" });
      }
    } else setData({ ...data, [event.name]: event.value });
  };

  const handleDrop = (event, fileType) => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const droppedFile = droppedFiles[0];

      // Update the state or perform any additional actions based on fileType
      if (fileType === "document") {
        setData({ ...data, document: droppedFile });
      } else if (fileType === "logo") {
        setData({ ...data, logo: droppedFile });
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const getAdminComplianceType = async () => {
    setShowLoader(true);
    const endpoint = process.env.REACT_APP_GET_THIRD_PARTY_COMPLIANCE_TYPE;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`
        )
          .then((res) => {
            let arrResponse = res.data.data;
            if (arrResponse?.length) {
              setAdminComplianceType(arrResponse);
              setComplianceType([
                ...complianceType,
                ...arrResponse.map((item) => item.name),
              ]);
            }
            setShowLoader(false);
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const uploadQuestionnaire = async () => {
    setShowLoader(true);
    let formData = new FormData();
    if (questionnaireId) formData.append("questionnaireId", questionnaireId);
    formData.append("emailId", localStorage.getItem("userEmail"));
    formData.append("type", data.type);
    formData.append("category", data.category);
    formData.append("description", data.description);
    formData.append("logo", data.logo);
    formData.append("document", data.document);

    const endpoint = process.env.REACT_APP_UPLOAD_THIRD_PARTY_QUESTIONNAIRE;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          formData
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowLoader(false);
            navigate("../upload-tab", { state: { tabIndex: tabIndex } });
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const checkValidation = () => {
    uploadQuestionnaire();
  };
  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Typography variant="h4" sx={{ mb: 5, mt: 5 }}>
        <span className="heading-main">Questionnaire</span>
      </Typography>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          className="m-l-0 click-to-begin"
          onClick={() =>
            navigate("../upload-tab", { state: { tabIndex: tabIndex } })
          }
        >
          Back
        </Button>
      </div>
      <form className="profile-form">
        <Card className="padding-square" sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            {/* Type */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  id="demo-simple-select"
                  label="Type"
                  name="type"
                  value={data.type}
                  onChange={handleChange}
                >
                  <MenuItem value="public">Public</MenuItem>
                  <MenuItem value="private">Private</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Category */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                {data.custom ? (
                  <TextField
                  name="category"
                  label="Category"
                  value={data.category}                
                   />
                ) : (
                  <>
                  <InputLabel id="demo-simple-select-label">
                  Category
                </InputLabel>
                  <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Category"
                  name="category"
                  value={data.category}
                  onChange={handleChange}
                >
                  {complianceType?.map((name, ind) => (
                    <MenuItem key={ind} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                  </>
                )}
              </FormControl>
            </Grid>

            {/* description */}
            <Grid item xs={12} md={12}>
              <TextareaAutosize
                fullWidth
                aria-label="empty textarea"
                placeholder="Description"
                minRows={4}
                className="fullWidthTextarea"
                name="description"
                value={data.description}
                onChange={handleChange}
              />
            </Grid>

            {/* Document */}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="center-upload  document-preview"
              onDrop={(e) => handleDrop(e, "document")}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <CardHeader title={"Upload Document"} sx={{ pl: 0 }} />
              <Button
                htmlFor="file-upload"
                size="small"
                variant="contained"
                color="primary"
                component="label"
                className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                  }`}
                style={{ with: "100%" }}
                title="Select File"
              >
                {data.document ? (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {typeof data?.document == "string"
                      ? data.document
                      : data.document?.name}
                  </div>
                ) : (
                  <>
                    <span style={{ color: "#007bff" }}>Upload Document </span>
                    &nbsp; or Just Drag and Drop
                  </>
                )}
              </Button>
              <input
                name="document"
                id="file-upload"
                type="file"
                style={{ display: "none", width: "100%" }}
                onChange={handleChange}
              />
            </Grid>

            {/* Logo */}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="center-upload logo-preview"
              onDrop={(e) => handleDrop(e, "logo")}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <CardHeader title={"Upload Logo"} sx={{ pl: 0 }} />
              <Button
                htmlFor="logo-upload"
                size="small"
                variant="contained"
                color="primary"
                component="label"
                className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                  }`}
                style={{ with: "100%" }}
                title="Select Image"
              >
                {data.logo ? (

                  <div>
                    {/* {console.log(data)} */}
                    {data.category == "Others" ? (

                      <img
                        className="preview-image"
                        src={
                          typeof data?.logo == "string"
                            ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${data?.logo}`
                            : URL.createObjectURL(data?.logo)
                        }
                        alt="icon"
                      />
                    ) :
                      data.custom ? <img className="preview-image" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${data?.logo}`} alt="logo" /> :
                        (
                          <img
                            className="preview-image"
                            src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_ASSETS}/${data?.logo}`}
                            alt="icon"
                          />
                        )}
                  </div>
                ) : data.custom ? <img src={QuestionnaireLogo} alt="logo"/> : (
                  <>
                    <span style={{ color: "#007bff" }}>Upload Logo</span>&nbsp;
                    or Just Drag and Drop
                  </>
                )}
              </Button>
              <input
                disabled={data.category !== "Others" || data.custom}
                name="logo"
                id="logo-upload"
                type="file"
                accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                style={{ display: "none", with: "100%" }}
                onChange={handleChange}
              />
            </Grid>

            <div className="btn-arrange">
              <Button
                variant="contained"
                className="click-to-begin m-l-0 cancel-btn"
                onClick={() =>
                  navigate("../upload-tab", { state: { tabIndex: tabIndex } })
                }
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ color: "white" }}
                className="addnew export-btn"
                onClick={() => {
                  checkValidation();
                }}
              >
                Upload
              </Button>
            </div>
          </Grid>
        </Card>
      </form>
    </>
  );
};

export default AddQuestionnaire;
