import axios from "axios";
import React, { useEffect, useState } from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import { useSearchParams } from "react-router-dom";
import Loader from "../../Loader";
import "../../pages/css/showFile.css";

function ShowFile({ exceltheme }) {
  const [params] = useSearchParams();
  const url = params.get("path");
  const [excelData, setExcelData] = useState({ rows: [], cols: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [fileData, setFileData] = useState(null);
  useEffect(() => {
    getFileAPI();
  }, []);

  const getFileAPI = async () => {
    setShowLoader(true);

    const response = await axios.get(url, {
      responseType: "blob",
    });
    const data = response.data;
    setFileData(data);
    if (
      data.type ==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      ExcelRenderer(data, (err, resp) => {
        if (err) {
          console.error(err);
        } else {
          setExcelData({
            rows: resp.rows,
            cols: resp.cols,
          });
          console.log(resp.cols);
        }
      });
    } else {
      window.open(`${url}#toolbar=0`, "_self");
      setShowLoader(false);
    }
    setShowLoader(false);
  };

  const bodyStyle =
    exceltheme === "Dark" ? { backgroundColor: "#fff", color: "#000" } : {};

  return (
    <>
      <Loader show={showLoader} />

<div className="table-container-show-file">
  <OutTable
    data={excelData.rows}
    columns={excelData.cols}
    tableClassName="excel-table"
    tableHeaderRowClass="excel-table-header"
    showHeaderRow={false}
  />
  <style>{`body { background-color: ${bodyStyle.backgroundColor}; color: ${bodyStyle.color}; }`}</style>
</div>
    </>
  );
}

export default ShowFile;
