import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import {
  Card,
  CardHeader,
  Container,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";
import "../css/StructurePage.css";
import { DrawerContent } from "./Sidebar/DrawerContent";

const StructurePage = () => {
  const theme = localStorage.getItem("theme");
  const userEmail = localStorage.getItem("userEmail");
  const [showLoader, setshowLoader] = useState(false);
  const [profileData, setProfileData] = useState({
    userId: "",
    description: "",
    logo: "",
    links: [],
  });
  const [complianceCard, setComplianceCard] = useState([]);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [categories, setCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  useEffect(() => {
    getProfileData();
    getThirdPartyDetails();
  }, []);

  const getProfileData = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_THIRD_PARTY_PROFILE_DATA}`,
          {}
        )
          .then((res) => {
            if (res?.data?.code === 200) {
              const apiResponse = res?.data?.data; // Assuming data structure matches the example
              setProfileData({
                ...profileData,
                userId: apiResponse.userId,
                description: apiResponse.description,
                logo: apiResponse.logo,
                // logo: `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_PROFILE_LOGO}/${apiResponse.logo}`,
                links: apiResponse.links,
              });
              setshowLoader(false);
            } else {
              // enqueueSnackbar(res.data?.message, { variant: "warning" });
              setshowLoader(false);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setshowLoader(false);
    }
  };

  const getThirdPartyDetails = async () => {
    setshowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_THIRD_PARTY_DETAILS}`,
          {}
        )
          .then((res) => {
            if (res?.data?.code === 200) {
              const apiResponse = res?.data?.data; // Assuming data structure matches the example
              setComplianceCard(apiResponse.complianceCard);
              setQuestionnaire(apiResponse.questionnaire);
              setDocuments(apiResponse.documents);
              setCategories(apiResponse.categories);
              setshowLoader(false);
            } else {
              enqueueSnackbar(res.data?.message, { variant: "warning" });
              setshowLoader(false);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setshowLoader(false);
    }
  };

  const handleDrawerOpen = (section, index = "null") => {
    setCurrentSection(section);
    setIsDrawerOpen(true);
    setClickedIndex(index);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const truncateText = (text, startLength, endLength) => {
    if (text.length <= startLength + endLength) {
      return text;
    }
    const startText = text.slice(0, startLength);
    const endText = text.slice(-endLength);
    return `${startText}...${endText}`;
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
          <span className="heading-main ml25">Profile</span>
        </Typography>

        <Grid container spacing={2} className="ipad-space">
          {/* Overview */}
          {!showLoader ? (
            <>
              <Grid item xs={12} md={12} className="quest-base">
                <Card style={{ position: "relative" }} sx={{ mb: 1 }}>
                  <Link
                    to="/third-party/page-profile/edit"
                    state={profileData}
                    style={{ textDecoration: "none" }}
                  >
                    {profileData.description ||
                    profileData.links.length !== 0 ||
                    profileData.logo ? (
                      <Tooltip title="Edit" placement="left">
                        <ModeEditOutlineIcon className="edit-button" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add" placement="left">
                        <ModeEditOutlineIcon className="edit-button" />
                      </Tooltip>
                    )}
                  </Link>

                  {/* Description */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <div style={{ display: "flex" }}>
                        <CardHeader title={"Overview"} subheader={""} />

                        {profileData.userId && (
                          <ArrowForwardOutlinedIcon
                            style={{ marginTop: "16px", cursor: "pointer" }}
                            onClick={() => handleDrawerOpen("overview")}
                          />
                        )}
                      </div>
                      {profileData.description && (
                        <p
                          className="m-top-0"
                          style={{ wordBreak: "break-word" }}
                        >
                          {profileData.description}
                        </p>
                      )}
                    </Grid>

                    {/* Links */}
                    {profileData.links.length !== 0 && (
                      <Grid item xs={6} md={10} className="p-top-0">
                        <CardHeader title={"Links"} subheader={""} />
                        <div className="flex-policy">
                          <div className="statics-links">
                            {profileData.links.map((link, index) => (
                              <div key={index}>
                               <ul>
                                <li>
                                <a
                                  href={link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    display: "block",
                                    color: theme === "Dark" ? "white" : "black",
                                    wordWrap: 'break-word'
                                  }}
                                >
                                  {link}
                                  {/* Privacy Policy */}
                                </a>
                                </li>

                               </ul>
                              </div>
                            ))}
                            {profileData.links.length === 0 && (
                              <p style={{ padding: "0" }}>No Links Available</p>
                            )}
                          </div>
                        </div>
                      </Grid>
                    )}

                    {/* profile-logo */}
                    {profileData && profileData.logo && (
                      <Grid
                        item
                        xs={6}
                        md={2}
                        className="p-top-0 logo-align-middle"
                      >
                        <div className="flex-policy">
                          <div className="logo-image-policy">
                            <img
                              className="profile-logo"
                              src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_PROFILE_LOGO}/${profileData.logo}`}
                              alt="logo"
                            />
                          </div>
                        </div>
                      </Grid>
                    )}

                    {!profileData.userId && (
                      <Grid item xs={12} md={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <p>No Record Found</p>
                        </div>
                      </Grid>
                    )}
                  </Grid>

                  {/* Drawer */}
                  <DrawerContent
                    currentSection={currentSection}
                    overviewData={profileData}
                    complianceData={complianceCard}
                    questionnaireData={questionnaire}
                    documentsData={documents}
                    otherInfoData={categories}
                    isDrawerOpen={isDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    triggerIndex={clickedIndex}
                  />
                </Card>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} className="quest-base">
              <Card
                style={{ position: "relative" }}
                sx={{ mb: 1, width: "100%" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p>No Record Found</p>
                </div>
              </Card>
            </Grid>
          )}

          {/* Compliances and Audits */}
          <Grid item xs={12} md={12}>
            <Card sx={{ mb: 1 }}>
              <div style={{ display: "flex" }}>
                <CardHeader title={"Compliances and Audits"} subheader={""} />
                {complianceCard.length !== 0 && (
                  <ArrowForwardOutlinedIcon
                    style={{ marginTop: "16px", cursor: "pointer" }}
                    onClick={() => handleDrawerOpen("complianceAndAudits")}
                  />
                )}
              </div>
              {!complianceCard || complianceCard.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {complianceCard.map((compliance, index) => (
                    <div className="logos" key={compliance._id}>
                      <h5>{compliance.name}</h5>
                      <img
                        className="audits-images"
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_COMPLIANCE_LOGO}/${compliance?.logo}`}
                        alt="logo"
                        onClick={() =>
                          handleDrawerOpen("complianceAndAudits", index)
                        }
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.transform = "scale(1.1)")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                      <p className="link-circle">
                        {compliance.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon />
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>

          {/* Questionnaires */}
          <Grid item xs={12} md={12}>
            <Card sx={{ mb: 1 }}>
              <div style={{ display: "flex" }}>
                <CardHeader title={"Questionnaires"} subheader={""} />
                {questionnaire && questionnaire.length !== 0 && (
                  <ArrowForwardOutlinedIcon
                    style={{ marginTop: "16px", cursor: "pointer" }}
                    onClick={() => handleDrawerOpen("questionnaires")}
                  />
                )}
              </div>{" "}
              {!questionnaire || questionnaire.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>No Record Found</p>
                </div>
              ) : (
                <div className="d-flex-logo">
                  {questionnaire.map((quest, index) => (
                    <div className="logos" key={index}>
                      {/* <h5>{quest.category}</h5> */}
                      <h5>{truncateText(quest.category, 16, 3)}</h5>

                      {quest?.logo === "" ||
                      quest?.logo === "undefined" ||
                      quest?.logo === undefined ? (
                        <div
                          className="no-logo"
                          onClick={() =>
                            handleDrawerOpen("questionnaires", index)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="truncate"
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform = "scale(1.1)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          >
                            {quest.category}
                          </p>
                        </div>
                      ) : (
                        <img
                          className="audits-images"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${quest?.logo}`}
                          alt="logo"
                          onClick={() =>
                            handleDrawerOpen("questionnaires", index)
                          }
                          style={{ cursor: "pointer" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      )}
                      <p className="link-circle">
                        {quest.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon />
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>

          {/* Documents */}
          <Grid item xs={12} md={12}>
            <Card sx={{ mb: 1 }}>
              <div style={{ display: "flex" }}>
                <CardHeader title={"Documents"} subheader={""} />
                {documents.length !== 0 && (
                  <ArrowForwardOutlinedIcon
                    style={{ marginTop: "16px", cursor: "pointer" }}
                    onClick={() => handleDrawerOpen("documents")}
                  />
                )}
              </div>{" "}
              {!documents || documents.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {documents.map((doc, ind) => (
                    <div className="logos" key={ind}>
                      <h5>{truncateText(doc.category, 16, 3)}</h5>
                      {/* <h5>{doc.category}</h5>  */}
                      {doc?.logo === "" ||
                      doc?.logo === "undefined" ||
                      doc?.logo === undefined ? (
                        <div
                          className="no-logo"
                          onClick={() => handleDrawerOpen("documents", ind)}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="truncate"
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform = "scale(1.1)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          >
                            {/* {doc.title.split(" ").slice(0, 5).join(" ")}... */}
                            {truncateText(doc.title, 5, 5)}
                          </p>
                        </div>
                      ) : (
                        <img
                          className="audits-images"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_DOCUMENTS_LOGO}/${doc?.logo}`}
                          alt="logo"
                          onClick={() => handleDrawerOpen("documents", ind)}
                          style={{ cursor: "pointer" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      )}
                      <p className="link-circle">
                        {doc.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon />
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>

          {/* Other Info */}
          <Grid item xs={12} md={12}>
            <Card sx={{ mb: 1 }}>
              <div style={{ display: "flex" }}>
                <CardHeader title={"Other Info"} subheader={""} />
                {categories.length !== 0 && (
                  <ArrowForwardOutlinedIcon
                    style={{ marginTop: "16px", cursor: "pointer" }}
                    onClick={() => handleDrawerOpen("otherInfo")}
                  />
                )}
              </div>{" "}
              {!categories || categories.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {categories.map((cat, index) => (
                    <div className="logos">
                      <h5>{cat.category}</h5>
                      <img
                        className="audits-images"
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_CATEGORY_LOGO}/${cat?.logo}`}
                        alt="logo"
                        onClick={() => handleDrawerOpen("otherInfo", index)}
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.transform = "scale(1.1)")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                      <p className="link-circle">
                        {/* {cat.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon />
                        )} */}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default StructurePage;
