import React, { useState } from 'react';
import "../css/addQuestionnaire.css";
import {
    Typography,
    FormControl,
    Box,
    TextField,
    Button,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    MenuItem,
    Grid,
    Card,
    Modal, CardHeader
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import QuestionnairePreview from './QuestionnairePreview';
import Loader from '../../Loader';
import { useSnackbar } from 'notistack';
import PostRequest from "../../components/apiConnections/postRequest";
import { Link, useNavigate } from 'react-router-dom';

const AddQuestionnaireSet = () => {
    const theme = localStorage.getItem("theme");
    const { enqueueSnackbar } = useSnackbar();
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        logo: null,
        sections: [{ title: "", questions: [] }],
    });
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const handleInputChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleSectionTitleChange = (event, sectionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].title = value;
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleQuestionTextChange = (event, sectionIndex, questionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex].text = value;
        setFormData({ ...formData, sections: updatedSections });
    };

    const addSection = () => {
        setFormData({
            ...formData,
            sections: [...formData.sections, { title: "", questions: [] }],
        });
    };

    const removeSection = (index) => {
        const updatedSections = [...formData.sections];
        updatedSections.splice(index, 1);
        setFormData({ ...formData, sections: updatedSections });
    };

    const addQuestion = (sectionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions.push({ text: "", options: [] });
        setFormData({ ...formData, sections: updatedSections });
    };

    const removeQuestion = (sectionIndex, questionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions.splice(questionIndex, 1);
        setFormData({ ...formData, sections: updatedSections });
    };

    const addOption = (sectionIndex, questionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex].options.push("");
        setFormData({ ...formData, sections: updatedSections });
    };

    const removeOption = (sectionIndex, questionIndex, optionIndex) => {
        const updatedSections = [...formData.sections];
        updatedSections[sectionIndex].questions[questionIndex].options.splice(optionIndex, 1);
        setFormData({ ...formData, sections: updatedSections });
    };

    const validateForm = () => {
        // Basic validation, you may want to enhance this based on your requirements
        if (!formData.title || formData.title.trim() === '') {
            enqueueSnackbar('Please fill title field', { variant: 'warning' });
            return false;
        } else if (!formData.description || formData.description.trim() === '') {
            enqueueSnackbar('Please fill description field', { variant: 'warning' });
            return false;
        } else if (formData.sections.length === 0) {
            enqueueSnackbar('Please add section', { variant: 'warning' });
            return false;
        } 
        // else if (!formData.logo) {
        //     enqueueSnackbar('Please upload logo', { variant: 'warning' });
        //     return false;
        // }

        // Check if any category, question, option type, or option score is empty or contains only spaces
        for (const [index, section] of formData.sections?.entries()) {
            if (!section.title || section.title.trim() === '') {
                enqueueSnackbar(`Please fill Category Name in section ${index + 1}.`, { variant: 'warning' });
                return false;
            }
            if (!section.questions?.length) {
                enqueueSnackbar(`Please add questions in section ${index + 1}`, { variant: 'warning' });
                return false;
            }
            for (const question of section.questions) {
                if (!question.text || question.text.trim() === '') {
                    enqueueSnackbar(`Please fill question text in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }

                if (!question.type) {
                    enqueueSnackbar(`Please select question type in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (!question.options?.length) {
                    enqueueSnackbar(`Please add options for question in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
                if (question.options.some((option) => !option.title || option.title.trim() === '')) {
                    enqueueSnackbar(`Please fill all option field with score of question in section ${index + 1}`, { variant: 'warning' });
                    return false;
                }
            }
        }
        return true;
    };


    const handleQuestionTypeChange = (event, sectionIndex, questionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];
        const defaultValue = 'MCQ'; // Set your desired default value here

        updatedSections[sectionIndex].questions[questionIndex].type = value !== '' ? value : defaultValue;
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleSelectChange = (event, sectionIndex, questionIndex, optionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];

        // Ensure that options is an array before trying to access its elements
        if (!Array.isArray(updatedSections[sectionIndex].questions[questionIndex].options)) {
            updatedSections[sectionIndex].questions[questionIndex].options = [];
        }



        updatedSections[sectionIndex].questions[questionIndex].options[optionIndex] = { title: value };
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleScoreChange = (event, sectionIndex, questionIndex, optionIndex) => {
        const { value } = event.target;
        const updatedSections = [...formData.sections];

        if (!Array.isArray(updatedSections[sectionIndex].questions[questionIndex].options)) {
            updatedSections[sectionIndex].questions[questionIndex].options = [];
        }

        if (typeof updatedSections[sectionIndex].questions[questionIndex].options[optionIndex] !== 'object') {
            updatedSections[sectionIndex].questions[questionIndex].options[optionIndex] = {};
        }

        const defaultValue = 0; // Set your desired default value here
        updatedSections[sectionIndex].questions[questionIndex].options[optionIndex].score = value !== '' ? value : defaultValue;
        setFormData({ ...formData, sections: updatedSections });
    };

    const handleNextClick = () => {
        if (validateForm()) {
            setShowPreviewModal(true);
        } else {
            // alert('Please fill in all required fields.');
        }
    };

    const handleCloseModal = () => {
        setShowPreviewModal(false);
    };

    const handleEditClick = () => {
        setShowPreviewModal(false);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            // Send data to the API in the specified format
            const categories = formData.sections.map((section) => ({
                category: section.title,
                questions: section.questions.map((question) => ({
                    text: question.text,
                    optionType: question.type,
                    options: question.options.map((option) => ({
                        title: option.title,
                        // score: option.score,
                    })),
                })),
            }))

            let payload = new FormData();
            payload.append("set", formData.title)
            payload.append("description", formData.description)
            payload.append("categories", JSON.stringify(categories))
            payload.append("logo", formData.logo)
            payload.append("email", localStorage.getItem("userEmail"))

            // Call your API here with 'apiData'
            console.table('Data to be sent to API:', payload);

            setShowLoader(true);
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    {},
                    "refreshToken"
                );
                if (refreshToken) {
                    await PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_TP_QUESTIONNAIRE_SET}`,
                        payload
                    )
                        .then((resChart) => {
                            console.log(resChart)
                            enqueueSnackbar('Questionnaire set added successfully', { variant: 'success' });
                            setShowLoader(false);
                            setShowPreviewModal(false)
                            setFormData({
                                title: '',
                                description: '',
                                sections: [{ title: "", questions: [] }],
                            });
                            setTimeout(() => {
                                navigate("/third-party/questionnaire")
                            }, 2000)
                        })
                        .catch((errChart) => {
                            enqueueSnackbar(errChart.message, { variant: 'error' });
                            setShowLoader(false);
                        })
                } else {
                    setShowLoader(false);
                }
            }
            catch (error) {
                enqueueSnackbar(error.message, { variant: 'error' });
                setShowLoader(false);
            }
        } else {
            alert('Please fill in all required fields.');
        }
    };
    const handleFileChange = (files) => {
        // setShowLoader(true);
        let file = files.files[0];
        if (!file) return null;
        const checkImage = file;
        const fileExtension = checkImage.name.replace(/^.*\./, "");
        if (["jpg", "png", "jpeg"].includes(fileExtension)) {
            setFormData({ ...formData, logo: checkImage });
            // setShowLoader(false);
        } else {
            enqueueSnackbar("Please upload image file only", { variant: "error" });
            // setShowLoader(false);
        }
    }
    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        const droppedFile = event.dataTransfer;
        if (droppedFile.length > 0) {
            handleFileChange(droppedFile)
        } else {
            enqueueSnackbar("Please upload only one file", { variant: "error" });
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    return (
        <>
            <Loader show={showLoader} />
            <Helmet>
                <title> Questionnaire | Genesis Platform</title>
            </Helmet>

            <Typography variant="h4" sx={{ mb: 5 }}>
                <span className='heading-main'>Questionnaire Library</span>
            </Typography>
            <Grid container spacing={2} pb={2} >
                <Grid item xs={12} md={12} lg={12}>
                    <Card className="padding-square">
                        <Link to={`../questionnaire`}>
                            <Button variant="contained" className="click-to-begin m-l-0 cancel-btn">Back</Button>
                        </Link>
                        <form className={theme === "Light" ? "light-mode-class full-form" : "dark-mode-class full-form"}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="center-upload logo-preview"
                                onDrop={(e) => handleDrop(e)}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                            >
                                <CardHeader title={"Upload Logo"} sx={{ pl: 0 }} />
                                <Button
                                    htmlFor="logo-upload"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                                        }`}
                                    style={{ with: "100%" }}
                                    title="Select Image"
                                >
                                    {formData.logo ? (
                                        <div>
                                            {console.log(formData.logo)}
                                            <img
                                                className="preview-image"
                                                src={
                                                    typeof formData.logo == "string"
                                                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${formData.logo}`
                                                        : URL.createObjectURL(formData.logo)
                                                }
                                                alt="icon"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <span style={{ color: "#007bff" }}>Upload Logo</span>&nbsp;
                                            or Just Drag and Drop
                                        </>
                                    )}
                                </Button>
                                <input
                                    name="logo"
                                    id="logo-upload"
                                    type="file"
                                    accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                                    style={{ display: "none", with: "100%" }}
                                    onChange={(e) => { e.preventDefault(); handleFileChange(e.target) }}
                                />
                            </Grid>
                            <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }} className='title-label'>
                                    Title
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    value={formData.title}
                                    onChange={(e) => handleInputChange('title', e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <Typography variant="h6" sx={{ mb: 2 }} className='title-label'>
                                    Description
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formData.description}
                                    onChange={(e) => handleInputChange('description', e.target.value)}
                                    className='textarea-space'
                                />
                            </FormControl>
                            <div className='d-justifybetween'>
                                <Button variant="contained" className='addsection' onClick={addSection} sx={{ mb: 2 }}>
                                    Add Section/Category
                                </Button>
                            </div>
                            {formData.sections.map((section, sectionIndex) => (
                                <div key={sectionIndex} className='accordion-part'>
                                    <Accordion sx={{ mb: 2 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`section${sectionIndex}-content`}
                                            id={`section${sectionIndex}-header`}
                                        >
                                            <Typography variant="h6" className='expand-section'>Section {sectionIndex + 1}</Typography>
                                            <IconButton onClick={() => removeSection(sectionIndex)} sx={{ ml: 'auto' }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <FormControl fullWidth sx={{ mb: 2 }}>
                                                <h4 variant="h6" sx={{ mb: 2 }} className='sub-categoryname'>
                                                    Section/Category Name
                                                </h4>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    value={section.title}
                                                    onChange={(e) => handleSectionTitleChange(e, sectionIndex)}
                                                />
                                            </FormControl>
                                            <Button variant="contained" className='addsection' sx={{ mb: 2 }} onClick={() => addQuestion(sectionIndex)}>
                                                Add Question
                                            </Button>
                                            {section.questions.map((question, questionIndex) => (
                                                <div key={questionIndex}>
                                                    <FormControl key={questionIndex} fullWidth sx={{ mb: 2 }}>
                                                        <Typography variant="h6" sx={{ mb: 2 }} >
                                                            <span className='section-headingpart'>{`Q ${questionIndex + 1}`}</span>
                                                        </Typography>
                                                        <Box display="flex" alignItems="center">
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                value={question.text}
                                                                onChange={(e) => handleQuestionTextChange(e, sectionIndex, questionIndex)}
                                                            />
                                                            <Select
                                                                value={question.type || ''}
                                                                onChange={(event) => handleQuestionTypeChange(event, sectionIndex, questionIndex)}
                                                                sx={{ width: 100, ml: 2 }}
                                                                displayEmpty  // This allows you to display an empty value
                                                                inputProps={{ 'aria-label': 'Select Question Type' }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select Question Type
                                                                </MenuItem>
                                                                <MenuItem value="MCQ">Multiple Choice Question</MenuItem>
                                                                <MenuItem value="SCQ">Single Choice Question</MenuItem>
                                                            </Select>
                                                            <IconButton onClick={() => removeQuestion(sectionIndex, questionIndex)}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </Box>
                                                        {question.options.map((option, optionIndex) => (
                                                            <FormControl key={optionIndex} fullWidth sx={{ mb: 2 }}>
                                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                                    <span className='section-headingpart'>{`Option ${optionIndex + 1}`}</span>
                                                                </Typography>
                                                                <Box display="flex" alignItems="center">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        value={option.title}
                                                                        required
                                                                        onChange={(e) => handleSelectChange(e, sectionIndex, questionIndex, optionIndex)}
                                                                    />
                                                                    {/* <Select
                                                                        value={option.score > -1 ? option.score : ''}
                                                                        onChange={(event) => handleScoreChange(event, sectionIndex, questionIndex, optionIndex)}
                                                                        sx={{ width: 100, ml: 2 }}
                                                                        displayEmpty  // This allows you to display an empty value
                                                                        inputProps={{ 'aria-label': 'Select Score' }}
                                                                    >
                                                                        <MenuItem value="" disabled>
                                                                            Select Score
                                                                        </MenuItem>
                                                                        {Array.from({ length: 6 }, (_, i) => (
                                                                            <MenuItem key={i} value={i}>
                                                                                {i}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select> */}

                                                                    <IconButton onClick={() => removeOption(sectionIndex, questionIndex, optionIndex)}>
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </Box>
                                                            </FormControl>
                                                        ))}
                                                    </FormControl>
                                                    <Button variant="contained" className='addsection' onClick={() => addOption(sectionIndex, questionIndex)}>
                                                        Add Option
                                                    </Button>
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                            <Button variant="contained" onClick={handleNextClick} sx={{ mt: 2 }} className="next-button min-h40">
                                Next
                            </Button>
                                <QuestionnairePreview
                                    open={showPreviewModal}
                                    handleClose={() => setShowPreviewModal(false)}
                                    onClose={handleEditClick}
                                    submit={handleSubmit}
                                    title = {formData.title}
                                    description={formData.description}
                                    sections={formData.sections}
                                    showSubmit={true}
                                />
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default AddQuestionnaireSet;
