import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CardHeader,
  Grid,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import Loader from "../../Loader";
import "../../pages/css/AddFile.css";
import "../../pages/css/complianceCard.css";
import PostRequest from "../apiConnections/postRequest";

function UploadQuestionnaireSet({ open, handleClose, handleSuccess, onClose }) {
  const theme = localStorage.getItem("theme");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fileData, setFileData] = useState();
  const [logo, setLogo] = useState();
  const [selectedLogoName, setSelectedLogoName] = useState("");
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadError, setUploadError] = useState("");


  const handleFileChange = (files, type) => {
    // setShowLoader(true);
    setUploadError("");
    // console.log(type);
    if (type == "document") {
      let file = files.files[0];
      if (!file) return null;
      const checkImage = file;
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      const fileSize = Math.round(checkImage.size / 1024);
      if (fileExtension === "xlsx") {
        if (fileSize <= 20000) {
          setFileData(checkImage);
          setSelectedFileName(checkImage.name);
          // setShowLoader(false);
        } else {
          enqueueSnackbar("Please upload less than 2 mb file.", {
            variant: "error",
          });
          // setShowLoader(false);
        }
      } else {
        setUploadError("*Please upload excel file only.");
        enqueueSnackbar("Please upload excel file only.", { variant: "error" });
        setFileData(null);
        setSelectedFileName(null);
        // setShowLoader(false);
      }
    } else {
      let file = files.files[0];
      if (!file) return null;
      const checkImage = file;
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      if (["jpg", "png", "jpeg"].includes(fileExtension)) {
        console.log("e");
        setLogo(checkImage);
        setSelectedLogoName(checkImage.name);
        // setShowLoader(false);
      } else {
        enqueueSnackbar("Please upload image file only", { variant: "error" });
        setLogo(null);
        setSelectedLogoName(null);
        // setShowLoader(false);
      }
    }
  };

  
  const do_upload = async (e) => {
    e.preventDefault();
    if (!fileData) {
      enqueueSnackbar("Please upload file", {
        variant: "error",
      });
      return null;
    }
    setShowLoader(true);
    setDisableUploadButton(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        let endPoint =
          process.env.REACT_APP_UPLOAD_TP_QUESTIONNAIRE_SET_BY_EXCEL;
        let formData = new FormData();
        formData.append("logo", logo);
        formData.append("file", fileData);
        formData.append("email", localStorage.getItem("userEmail"));
        const uploadJson = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
          formData,
          "ImageUpload"
        );
        if (uploadJson && uploadJson.status === 200) {
          if (uploadJson.data.code === 200) {
            enqueueSnackbar(uploadJson.data?.message, { variant: "success" });
            setShowLoader(false);
            setTimeout(() => {
              setDisableUploadButton(false);
            }, 3000);
            setFileData();
            setSelectedFileName(null);
            handleSuccess();
          } else {
            if (uploadJson.data?.message) {
              enqueueSnackbar(`${uploadJson.data.message}`, {
                variant: "error",
                onClick: () => {
                  closeSnackbar();
                },
              });
            } else enqueueSnackbar("File not uploaded", { variant: "error" });
            setShowLoader(false);
            setTimeout(() => {
              setDisableUploadButton(false);
            }, 3000);
          }
        } else {
          if (uploadJson.data?.message) {
            enqueueSnackbar(`${uploadJson.data.message}`, {
              variant: "error",
              onClick: () => {
                closeSnackbar();
              },
            });
          } else enqueueSnackbar("File not uploaded", { variant: "error" });
          setShowLoader(false);
          setTimeout(() => {
            setDisableUploadButton(false);
          }, 3000);
        }
      }
    } catch (e) {
      if (e.message)
        enqueueSnackbar(`${e.message}`, {
          variant: "error",
          // preventDuplicate: true,
          onClick: () => {
            closeSnackbar();
          },
        });
      else enqueueSnackbar("Error in upload file.", { variant: "error" });
      setShowLoader(false);
      setTimeout(() => {
        setDisableUploadButton(false);
      }, 3000);
    }
  };

  const handleDrop = (event, fileType) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer;
    if (droppedFile.length > 0) {
      handleFileChange(
        droppedFile,
        fileType === "document" ? "document" : "logo"
      );
    } else {
      enqueueSnackbar("Please upload only one file", { variant: "error" });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <>
      <Loader show={showLoader} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={`${open ? 'blur-background' : ''}`}
        >
          <div className={theme === "Light" ? "light-mode-class addfile" : "dark-mode-class addfile"}>
            <Box
              className="tabs-bg preview-popup"
              sx={{ bgcolor: "background.paper" }}
            >
              <Button onClick={onClose} className="cross-right-side">
                <CloseIcon />
              </Button>
              <div className="bgaddfiles top-60">
                <Typography
                  variant="h4"
                  gutterBottom
                  className="centerpoint upload-questionnaire"
                >
                  <h2 className="heading-main mb-28">Upload a Questionnaire</h2>
                  <Link
                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/assets/demo-questionnaire.xlsx`}
                    download="demo-questionnaire.xlsx"
                    underline="none"
                  >
                    Download demo file
                  </Link>
                  <Typography sx={{ marginTop: "10px" }}>
                    Please upload excel file in demo file format only.
                  </Typography>
                  <Box sx={{ color: "red" }}>{uploadError || null}</Box>
                  {/* Logo */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="center-upload logo-preview"
                    onDrop={(e) => handleDrop(e, "logo")}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    <CardHeader title={"Upload Logo"} sx={{ pl: 0 }} />
                    <Button
                      htmlFor="logo-upload"
                      size="small"
                      variant="contained"
                      color="primary"
                      component="label"
                      className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                        }`}
                      style={{ with: "100%" }}
                      title="Select Image"
                    >
                      {logo ? (
                        <div>
                          <img
                            className="preview-image"
                            src={
                              typeof logo == "string"
                                ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${logo}`
                                : URL.createObjectURL(logo)
                            }
                            alt="icon"
                          />
                        </div>
                      ) : (
                        <>
                          <span style={{ color: "#007bff" }}>Upload Logo</span>
                          &nbsp; or Just Drag and Drop
                        </>
                      )}
                    </Button>
                    <input
                      name="logo"
                      id="logo-upload"
                      type="file"
                      accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                      style={{ display: "none", with: "100%" }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleFileChange(e.target, "logo");
                      }}
                    />
                  </Grid>
                  {/* Document */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className="center-upload  document-preview"
                    onDrop={(e) => handleDrop(e, "document")}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    <CardHeader title={"Upload Excel File"} sx={{ pl: 0 }} />
                    <Button
                      htmlFor="file-upload"
                      size="small"
                      variant="contained"
                      color="primary"
                      component="label"
                      className={`upload-new-plus w-100 ${isDragging ? "drag-over" : ""
                        }`}
                      style={{ with: "100%" }}
                      title="Select File"
                    >
                      {fileData ? (
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {typeof fileData == "string"
                            ? fileData
                            : fileData?.name}
                        </div>
                      ) : (
                        <>
                          <span style={{ color: "#007bff" }}>
                            Upload Excel File{" "}
                          </span>
                          &nbsp; or Just Drag and Drop
                        </>
                      )}
                    </Button>
                    <input
                      name="document"
                      id="file-upload"
                      type="file"
                      accept=".xlsx"
                      style={{ display: "none", width: "100%" }}
                      onChange={(e) => {
                        e.preventDefault();
                        handleFileChange(e.target, "document");
                      }}
                    />
                  </Grid>

                  <LoadingButton
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                    color="success"
                    className="save-file-btn"
                    sx={{ width: "auto", marginTop: "15px" }}
                    onClick={(e) => do_upload(e)}
                    disabled={disableUploadButton}
                  >
                    Save
                  </LoadingButton>
                </Typography>
              </div>
            </Box>
          </div>
        </Modal>
    </>
  );
}

export default UploadQuestionnaireSet;
