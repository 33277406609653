import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MailSend from "../../assets/mailsend.svg";
import PostRequest from "../../components/apiConnections/postRequest";
import defaultLogo from '../../assets/insurance-company.png'
import Loader from "../../Loader";
import "../css/EditProfile.css";
import ModalDialog from "../ModalDialog.js";

const EditProfile = () => {
  const location = useLocation();
  const receivedData = location.state;
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);
  const [formData, setFormData] = useState({
    email: localStorage.getItem("userEmail"),
    description: receivedData.description,
    logo: receivedData.logo,
    links: Array.isArray(receivedData.links) ? receivedData.links : [],
  });
  const [fieldErrors, setFieldErrors] = useState({
    description: false,
    links: Array(formData.links.length).fill(false),
  });
  const [open, setOpen] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleDescriptionChange = (e) => {
    setFormData({
      ...formData,
      description: e.target.value,
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      description: e.target.value.trim() === "",
    }));
  };

  // const handleLogoChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const fileType = selectedFile.type;

  //   if (selectedFile) {
  //     const blob = new Blob([selectedFile], { type: fileType });
  //     const file = new File([blob], "logo.png", { type: fileType });
  //     setFormData({
  //       ...formData,
  //       logo: file,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       logo: receivedData.logo,
  //     });
  //   }
  // };
  const handleLogoChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      // Check if the selected file is an image
      if (fileType.startsWith("image/")) {
        const blob = new Blob([selectedFile], { type: fileType });
        const file = new File([blob], "logo.png", { type: fileType });
        setFormData({
          ...formData,
          logo: selectedFile,
        });
      } else {
        // Display an error message when a non-image file is selected
        enqueueSnackbar("Please select a valid image file.", {
          variant: "error",
        });

        // Reset the logo to the previous value
        setFormData({
          ...formData,
          logo: receivedData.logo,
        });
      }
    }
  };

  // const handleLinkChange = (index, value) => {
  //   const updatedFormData = { ...formData };
  //   updatedFormData.links[index] = value;
  //   setFormData(updatedFormData);

  //   setFieldErrors((prevErrors) => {
  //     const newLinkErrors = [...prevErrors.links];
  //     newLinkErrors[index] = value.trim() === "";
  //     return { ...prevErrors, links: newLinkErrors };
  //   });
  // };

  const handleLinkChange = (e) => {
    const linksInput = e.target.value;
    const linksArray = linksInput.split(",").map((link) => link.trim());

    setFormData({
      ...formData,
      links: linksArray,
    });

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      links: linksArray.map((link) => link === ""),
    }));
  };

  const validate = () => {
    const { description, links } = formData;

    // if description is empty or contains only spaces
    const descriptionError = description.trim() === "";

    // if any link is empty or contains only spaces
    const linksError = links.map((link) => link.trim() === "");

    setFieldErrors({
      description: descriptionError,
      links: linksError,
    });

    // If any field is empty or contains only spaces, show an alert
    if (descriptionError || linksError.includes(true)) {
      enqueueSnackbar("Please fill in all the required fields.", {
        variant: "error",
      });
    }

    // Return true if no field is empty or contains only spaces
    return !(descriptionError || linksError.includes(true));
  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={MailSend} alt="icon" />
        <h3 className="send-assessments">Update Third Party Profile ?</h3>
      </Typography>
    );
  };

  const updateThirdPartyProfile = async () => {
    setShowLoader(true);
    let formDt = new FormData();
    formDt.append("email", formData.email);
    formDt.append("description", formData.description);
    formDt.append("logo", formData.logo);
    formDt.append("links", JSON.stringify(formData.links));

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_THIRD_PARTY_PROFILE_DATA}`,
          formDt
        )
          .then((res) => {
            if (res?.data?.code === 200) {
              enqueueSnackbar(res.data?.message, { variant: "success" });
              setOpen(false);
              setShowLoader(false);
              navigate("../page-profile");
            } else {
              enqueueSnackbar(res.data?.message, { variant: "warning" });
              setShowLoader(false);
            }
          })
          .catch((err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      if (error.message) {
        enqueueSnackbar(error.message, { variant: "error" });
      } else {
        enqueueSnackbar("Internal Error", { variant: "error" });
      }
      setShowLoader(false); // Hide loader in case of an error
    }
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>

      <Typography variant="h4" sx={{ mt: 4, mb: 5 }}>
        <span className="heading-main ml25">Edit Profile</span>
      </Typography>
      <Grid container spacing={2} className="ipad-space" sx={{ mb: 2 }}>
        <Grid item xs={12} md={12} lg={12}>
          <Card className="padding-square" style={{ padding: "20px" }}>
            <Link to={`../page-profile`} className="back-button">
              <Button variant="contained" className="">
                Back
              </Button>
            </Link>
            <form action="" className="full-form">
              <Grid container spacing={2}>
                {/* Description  */}
                <Grid item xs={8} md={8}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{ mb: 2 }}
                      className="title-label"
                    >
                      Description
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      error={fieldErrors.description}
                      multiline
                      rows={4}
                      value={formData.description}
                      onChange={handleDescriptionChange}
                      className="textarea-space"
                    />
                  </FormControl>
                </Grid>

                {/* Logo */}
                <Grid item xs={4} md={4}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{ mb: 2 }}
                      className="title-label"
                    >
                      Logo
                    </Typography>
                    <Button
                      htmlFor="logo-upload"
                      size="small"
                      variant="outlined"
                      color="secondary"
                      component="label"
                      title="Select Image"
                      className="image-button"
                    >
                      <div className="image-container">
                        <img
                          style={{ width: "100px", objectFit: "contain" }}
                          src={
                            imageError ?
                              defaultLogo
                              : typeof formData?.logo == "string" && formData?.logo
                                ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_PROFILE_LOGO}/${formData.logo}`
                                : typeof formData?.logo == "object" ? URL.createObjectURL(formData?.logo) : defaultLogo
                          }
                          alt="icon"
                          className="image"
                        // onError={()=> setImageError(true)}
                        />
                        {/* <p>{formData.logo}</p> */}
                      </div>
                      <EditIcon className="edit-icon" />
                    </Button>
                    <input
                      name="logo"
                      id="logo-upload"
                      type="file"
                      accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                      style={{ display: "none", with: "100%" }}
                      onChange={handleLogoChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }} className="title-label">
                  Links
                </Typography>
                <TextField
                  variant="outlined"
                  value={formData.links.join(",")}
                  error={fieldErrors.links.some((linkError) => linkError)}
                  onChange={handleLinkChange}
                  helperText="Please provide valid links separated by commas."
                />
              </FormControl>

              <Button
                variant="contained"
                onClick={() => {
                  if (validate()) {
                    setOpen(true);
                  }
                }}
                className="next-button"
              >
                Update
              </Button>
            </form>
          </Card>
        </Grid>
      </Grid>
      <ModalDialog
        handleClose={() => setOpen(false)}
        open={open}
        onSubmit={updateThirdPartyProfile}
        contentData={ModalContent}
      />
    </>
  );
};

export default EditProfile;
