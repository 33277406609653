// theme
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
// components
import ScrollToTop from "./components/scroll-to-top";
// routes
import { SnackbarProvider } from "notistack";
import { useDispatch } from "react-redux";
import { changeTheme } from "./redux/themeSlice";
import Router from "./routes";
import { IdleTimer } from "./utils/IdleTimer";
// ----------------------------------------------------------------------
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});
export default function App() {
  const dispatch = useDispatch();
  const [theme, setTheme] = useState("Dark");

  const toggleTheme = () => {
    if (theme === "Light") {
      setTheme("Dark");
      dispatch(changeTheme("dark"));
      localStorage.setItem("theme", "Dark");
    } else {
      setTheme("Light");
      dispatch(changeTheme("light"));
      localStorage.setItem("theme", "Light");
    }
  };

  const [isShowSearch, setIsshowSearch] = useState(
    window.location.pathname.split("/")[2]
  );

  useEffect(() => {
    setIsshowSearch(window.location.pathname.split("/")[2]);
  }, []);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
    }
  }, []);

  useEffect(() => {
    let themeName;
    themeName =
      localStorage.getItem("theme") !== null
        ? localStorage.getItem("theme")
        : "Dark";
    setTheme(themeName);
    localStorage.setItem("theme", themeName);
  }, []);



  return (
    <>
      <ThemeProvider theme={theme === "Dark" ? darkTheme : lightTheme}>
        <CssBaseline />
        <ScrollToTop />
        <SnackbarProvider maxSnack={3} preventDuplicate={true}>
          <IdleTimer />
            <Router
              toggleTheme={() => toggleTheme()}
              theme={theme}
              isShowSearch={isShowSearch}
              exceltheme={theme}
            />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}
