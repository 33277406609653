import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from "@mui/icons-material";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest";
import Loader from "../../Loader";

const AddInfo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const myObject = location.state ? location.state.data : null;
  const tabIndex = location.state ? location.state.tabIndex : 0;
  const [categoryId, setCategoryId] = useState(myObject ? myObject._id : null);
  const [showLoader, setShowLoader] = useState(false);
  const type = ["public", "private"];
  const [data, setData] = useState({
    category: "",
    logo: "",
    subCategory: [
      {
        type: "",
        title: "",
        description: "",
      },
    ],
  });

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionChange = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  useEffect(() => {
    if (categoryId && myObject) {
      const { ...rest } = myObject;

      setData({
        ...rest,
      });
    }
  }, [categoryId, myObject]);

  const handleChange = (field, value, subCatIndex = null) => {
    setData((prevFormData) => {
      if (field === "logo") {
        // Validate if the selected file is an image
        if (value && value.type && value.type.startsWith("image/")) {
          return {
            ...prevFormData,
            [field]: value,
          };
        } else {
          // Handle the case when the selected file is not an image
          // alert("Please select a valid image file.");
          return prevFormData;
        }
      } else {
        if (subCatIndex !== null) {
          const updatedSubCategory = [...prevFormData.subCategory];
          updatedSubCategory[subCatIndex] = {
            ...updatedSubCategory[subCatIndex],
            [field]: value,
          };

          return {
            ...prevFormData,
            subCategory: updatedSubCategory,
          };
        } else {
          return {
            ...prevFormData,
            [field]: value,
          };
        }
      }
    });
  };

  const handleAddSubCategory = () => {
    setData((prevData) => ({
      ...prevData,
      subCategory: [
        ...prevData.subCategory,
        {
          type: "",
          title: "",
          description: "",
        },
      ],
    }));
  };

  const handleRemoveSubCategory = (subCatIndex) => {
    setData((prevData) => {
      const updatedSubCategory = [...prevData.subCategory];
      updatedSubCategory.splice(subCatIndex, 1);

      return {
        ...prevData,
        subCategory: updatedSubCategory,
      };
    });
  };

  const uploadCategory = async () => {
    setShowLoader(true);
    let formData = new FormData();
    if (categoryId) formData.append("categoryId", categoryId);
    formData.append("emailId", localStorage.getItem("userEmail"));
    formData.append("category", data.category);
    formData.append("logo", data.logo);
    formData.append("subCategory", JSON.stringify(data.subCategory));

    const endpoint = process.env.REACT_APP_UPLOAD_THIRD_PARTY_CATEGORY;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          formData
        )
          .then((res) => {
            enqueueSnackbar(res.data.message, { variant: "success" });
            setShowLoader(false);
            navigate("../upload-tab", { state: { tabIndex: tabIndex } });
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const checkValidation = () => {
    uploadCategory();
  };

  return (
    <>
      <Loader show={showLoader} />
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Typography variant="h4" sx={{ mb: 2, mt: 5 }}>
        <span className="heading-main">Other Information</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "end"}}>
        <Button
          variant="contained"
          className="m-l-0 click-to-begin"
          onClick={() =>
            navigate("../upload-tab", { state: { tabIndex: tabIndex } })
          }
        >
          Back
        </Button>
      </div>
      {/* {invalidComplianceId ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography>Invalid Compliance</Typography>
        </Box>
      ) : ( */}
      <Grid container spacing={2} pb={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{ mb: 5, mt: 2 }} className="full-form">
            {/* <Link to={`../questionnaire-library`}>
              <Button
                variant="contained"
                className="click-to-begin m-l-0 cancel-btn"
              >
                Back
              </Button>
            </Link> */}
            <form action="" className="full-form">
              <Grid container spacing={2} pb={2}>
                {/* Category */}
                <Grid item xs={12} md={4} lg={4}>
                  <FormControl fullwidth="true">
                    <Typography
                      variant="h6"
                      sx={{ mb: 2 }}
                      className="title-label"
                    >
                      Category
                    </Typography>
                    <TextField
                      variant="outlined"
                      name="category"
                      value={data.category}
                      placeholder="Category"
                      onChange={(e) => handleChange("category", e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} lg={4}></Grid>
                <Grid item xs={12} md={4} lg={4}></Grid>

                {/* Logo */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="center-upload logo-preview"
                  // onDrop={(e) => handleDrop(e, "logo")}
                  // onDragOver={handleDragOver}
                  // onDragLeave={handleDragLeave}
                >
                  <CardHeader title={"Upload Logo"} sx={{ pl: 0 }} />
                  <Button
                    htmlFor="logo-upload"
                    size="small"
                    variant="contained"
                    color="primary"
                    component="label"
                    className="upload-new-plus w-100"
                    // className={`upload-new-plus w-100 ${
                    //   isDragging ? "drag-over" : ""
                    // }`}
                    style={{ with: "100%" }}
                    title="Select Image"
                  >
                    {data.logo ? (
                      <div>
                        <img
                          className="preview-image"
                          src={
                            typeof data?.logo == "string"
                              ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_CATEGORY_LOGO}/${data?.logo}`
                              : URL.createObjectURL(data?.logo)
                          }
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <>
                        <span style={{ color: "#007bff" }}>Upload Logo</span>
                        &nbsp; or Just Drag and Drop
                      </>
                    )}
                  </Button>
                  <input
                    name="logo"
                    id="logo-upload"
                    type="file"
                    accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG"
                    style={{ display: "none", with: "100%" }}
                    onChange={(e) => handleChange("logo", e.target.files[0])}
                  />
                </Grid>
              </Grid>

              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant="contained"
                  className="next-button min-h40"
                  onClick={handleAddSubCategory}
                  sx={{ mb: 2 }}
                >
                  Add Info
                </Button>
              </div>

              {data.subCategory.map((subCat, subCatIndex) => (
                <div key={subCatIndex} className="accordion-part">
                  <Accordion sx={{ mb: 2 }} className="accordion" expanded={subCatIndex === expandedIndex}
                      onChange={() => handleAccordionChange(subCatIndex)}>
                    <AccordionSummary
                      aria-controls={`section${subCatIndex}-content`}
                      id={`section${subCatIndex}-header`}
                      
                    >
                      
                      {subCatIndex === expandedIndex ? <ExpandLessIcon sx={{fontSize: "34px"}} /> : <ExpandMoreIcon sx={{fontSize: "34px"}} />}
                      
                      <IconButton
                        onClick={() => handleRemoveSubCategory(subCatIndex)}
                        sx={{ ml: "auto" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </AccordionSummary>

                    <AccordionDetails>
                      {/* Type */}
                      <Grid item xs={12} md={4} lg={4} sx={{ mb: 2 }}>
                        <FormControl fullwidth="true">
                          <Typography
                            variant="h6"
                            sx={{ mb: 2 }}
                            className="title-label"
                          >
                            Type
                          </Typography>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="type"
                            value={subCat.type}
                            onChange={(e) =>
                              handleChange("type", e.target.value, subCatIndex)
                            }
                          >
                            {type?.map((name, ind) => (
                              <MenuItem key={ind} value={name}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}></Grid>
                      <Grid item xs={12} md={4} lg={4}></Grid>

                      {/* Title */}
                      <FormControl fullwidth="true" sx={{ mb: 2 }}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 2 }}
                          className="title-label"
                        >
                          Title
                        </Typography>
                        <TextField
                          variant="outlined"
                          fullwidth="true"
                          name="title"
                          value={subCat.title}
                          placeholder="Title"
                          onChange={(e) =>
                            handleChange("title", e.target.value, subCatIndex)
                          }
                        />
                      </FormControl>

                      {/* Description */}
                      <FormControl fullwidth="true" sx={{ mb: 2 }}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 2 }}
                          className="title-label"
                        >
                          Description
                        </Typography>
                        <TextareaAutosize
                          name="description"
                          value={subCat.description}
                          fullwidth="true"
                          aria-label="empty textarea"
                          placeholder="Description"
                          minRows={4}
                          className="fullWidthTextarea"
                          onChange={(e) =>
                            handleChange(
                              "description",
                              e.target.value,
                              subCatIndex
                            )
                          }
                        />
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}

              <div className="btn-arrange">
                <Button
                  variant="contained"
                  className="click-to-begin m-l-0 cancel-btn"
                  onClick={() =>
                    navigate("../upload-tab", { state: { tabIndex: tabIndex } })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white" }}
                  className="addnew export-btn"
                  onClick={() => {
                    checkValidation();
                  }}
                >
                  Upload
                </Button>
              </div>
            </form>
          </Card>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};

export default AddInfo;
