import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  AppBar,
  Box,
  Card,
  CardHeader,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PostRequest from "../../../components/apiConnections/postRequest";
import Logo from "../../../components/logo";
import "../../css/public-page.css";
import { DrawerContent } from "../Public URL/Sidebar/DrawerContent";
import defaultLogo from '../../../assets/insurance-company.png'

const PublicPage = ({ data, toggleTheme }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    updateThirdPartyViewCount();
  }, []);

  const toggleModeButtonClick = () => {
    setTheme(theme === "Dark" ? `Light` : `Dark`);
    toggleTheme();
  };

  const updateThirdPartyViewCount = async () => {
    try {
      PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_THIRD_PARTY_PUBLIC_PAGE_VIEW_COUNT}`,
        { publicUrlId: data?.publicUrlId, countType: "view" }
      )
        .then((res) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  const handleDrawerOpen = (section, index = "null") => {
    setCurrentSection(section);
    setIsDrawerOpen(true);
    setClickedIndex(index);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const truncateText = (text, startLength, endLength) => {
    if (text.length <= startLength + endLength) {
      return text;
    }
    const startText = text.slice(0, startLength);
    const endText = text.slice(-endLength);
    return `${startText}...${endText}`;
  };

  return (
    <>
      <Helmet>
        <title> Public Page | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left public-background">
        <Box sx={{ display: "flex" }}>
          <AppBar
            component="nav"
            style={{ backgroundColor: "#121212 !important" }}
          >
            <Toolbar
              style={{
                margin: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: "75.78px",
              }}
              className={
                theme === "Light"
                  ? "light-background-appbar"
                  : "dark-background-appbar"
              }
            >
              <Box
                className="logo-sidebar"
                style={{ margin: 0, display: "flex", alignItems: "center" }}
              >
                <Logo /> &nbsp;&nbsp;
                <Typography
                  variant="h6"
                  component="div"
                  className={
                    theme === "Dark"
                      ? "dark-mode-heading"
                      : "light-mode-heading"
                  }
                >
                  Security Center
                </Typography>
              </Box>

              {/* <div>
                <Typography
                  variant="h6"
                  component="div"
                  className={
                    theme === "Dark"
                      ? "dark-mode-heading"
                      : "light-mode-heading"
                  }
                  style={{ marginRight: "140px" }}
                >
                  {data?.profileData.companyName}
                </Typography>
              </div> */}

              <div className="toggleMode">
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={theme === "Dark"}
                      onChange={() => toggleModeButtonClick()}
                    />
                  }
                  label={`${theme} Mode `}
                />
              </div>
            </Toolbar>
          </AppBar>
        </Box>

        <Grid
          container
          spacing={2}
          className="ipad-space public-space-top"
          sx={{ padding: "0px 40px 0px 40px" }}
        >
          <Typography variant="h4" sx={{ mt: 5 }}>
            <span className="heading-main ml25"></span>
          </Typography>

          <Grid container sx={{ mt: 5, mb: 3 }}>
            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                }}
              >
                <div className="logo-image-policy">
                  <img
                    className="public-page-logo"
                    src={imageError ? 
                      defaultLogo
                    : 
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_PROFILE_LOGO}/${data?.profileData.logo}`}
                    alt="logo"
                    onError={()=>setImageError(true)}
                  />
                </div>
                <b
                  // style={{ fontSize: "30px" }}
                  className={
                    theme === "Dark"
                      ? "dark-mode-heading"
                      : "light-mode-heading"
                  }
                >
                  {data?.profileData.companyName}
                </b>
              </div>
            </Grid>
          </Grid>

          <>
            <Grid item xs={12} md={12} className="quest-base border-public">
              <Card
                style={{ position: "relative" }}
                sx={{ mb: 1, width: "100%" }}
              >
                {/* Description */}
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: "24px 0px 0px 30px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginLeft: "15px",
                        marginRight: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDrawerOpen("overview")}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          style={{
                            width: "25px",
                            cursor: "pointer",
                          }}
                        >
                          <path d="M16 20V4H4V19C4 19.5523 4.44772 20 5 20H16ZM19 22H5C3.34315 22 2 20.6569 2 19V3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V10H22V19C22 20.6569 20.6569 22 19 22ZM18 12V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V12H18ZM6 6H12V12H6V6ZM8 8V10H10V8H8ZM6 13H14V15H6V13ZM6 16H14V18H6V16Z"></path>
                        </svg>
                        <CardHeader title={"Trust at Genesis"} subheader={""} />
                      </div>
                      {data?.profileData.description &&
                      data?.profileData.links.length !== 0 &&
                      data?.profileData.logo ? (
                        <svg
                          onClick={() => handleDrawerOpen("overview")}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          style={{
                            width: "28px",
                            cursor: "pointer",
                            marginRight: "5px",
                          }}
                        >
                          <path d="M10.071 4.92902L11.4852 6.34323L6.82834 11.0001L16.0002 11.0002L16.0002 13.0002L6.82839 13.0001L11.4852 17.6569L10.071 19.0712L2.99994 12.0001L10.071 4.92902ZM18.0001 19V5.00003H20.0001V19H18.0001Z"></path>
                        </svg>
                      ) : null}
                    </div>
                    <p
                      className="m-top-0"
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {data?.profileData.description}
                    </p>
                  </Grid>

                  {/* Links */}

                  {data?.profileData.description !== undefined &&
                  data?.profileData.links !== undefined &&
                  data?.profileData.logo !== undefined ? (
                    <Grid
                      item
                      xs={6}
                      md={10}
                      className="p-top-0"
                      style={{ padding: "24px 0px 0px 30px" }}
                    >
                      <CardHeader title={"Links"} subheader={""} />
                      <div className="flex-policy">
                        <div className="statics-links">
                          {data?.profileData.links.map((link, index) => (
                            <div key={index}>
                              <ul>
                                <li>
                                  <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      display: "block",
                                      color:
                                        theme === "Dark" ? "white" : "black",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {link}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "26px",
                        width: "100%",
                      }}
                    >
                      <p>No Record Found</p>
                    </div>
                  )}

                  {/* profile-logo */}
                  {/* {profileData && profileData.logo && (
                    <Grid
                      item
                      xs={6}
                      md={2}
                      className="p-top-0 logo-align-middle"
                    >
                      <div className="flex-policy">
                        <div className="logo-image-policy">
                          <img
                            className="profile-logo"
                            src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_PROFILE_LOGO}/${profileData.logo}`}
                            alt="logo"
                          />
                        </div>
                      </div>
                    </Grid>
                  )} */}
                </Grid>

                {/* Drawer */}
                <DrawerContent
                  currentSection={currentSection}
                  overviewData={data?.profileData}
                  complianceData={data?.complianceCard}
                  questionnaireData={data?.questionnaire}
                  documentsData={data?.documents}
                  otherInfoData={data?.categories}
                  isDrawerOpen={isDrawerOpen}
                  handleDrawerClose={handleDrawerClose}
                  triggerIndex={clickedIndex}
                  publicUrlId={data?.publicUrlId}
                />
              </Card>
            </Grid>
          </>

          {/* Compliances and Audits */}
          <Grid item xs={12} md={12} className="border-public">
            <Card sx={{ mb: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: "15px",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleDrawerOpen("complianceAndAudits")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <path d="M14 8V4H5V20H19V9H16V13.6195C16 14.4641 15.5544 15.2529 14.8125 15.7215L12 17.4978L9.18747 15.7215C8.4456 15.2529 8 14.4641 8 13.6195V8H14ZM21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM10 13.6195C10 13.7698 10.0872 13.9242 10.2554 14.0305L12 15.1323L13.7446 14.0305C13.9128 13.9242 14 13.7698 14 13.6195V10H10V13.6195Z"></path>
                  </svg>
                  <CardHeader title={"Compliances and Audits"} subheader={""} />
                </div>
                {data?.complianceCard.length !== 0 && (
                  <svg
                    onClick={() => handleDrawerOpen("complianceAndAudits")}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "28px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                  >
                    <path d="M10.071 4.92902L11.4852 6.34323L6.82834 11.0001L16.0002 11.0002L16.0002 13.0002L6.82839 13.0001L11.4852 17.6569L10.071 19.0712L2.99994 12.0001L10.071 4.92902ZM18.0001 19V5.00003H20.0001V19H18.0001Z"></path>
                  </svg>
                )}
              </div>
              {!data?.complianceCard || data?.complianceCard.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {data?.complianceCard.map((compliance, index) => (
                    <div className="logos" key={compliance._id}>
                      <div>
                        <h5>{compliance.name}</h5>
                        <img
                          className="audits-images"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_COMPLIANCE_LOGO}/${compliance?.logo}`}
                          alt="logo"
                          onClick={() =>
                            handleDrawerOpen("complianceAndAudits", index)
                          }
                          style={{ cursor: "pointer" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.2)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                        <p className="link-circle">
                          {compliance.type === "private" ? (
                            <LockIcon />
                          ) : (
                            <LockOpenIcon medium="true" />
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>

          {/* Questionnaires */}
          <Grid item xs={12} md={12} className="border-public">
            <Card sx={{ mb: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: "15px",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleDrawerOpen("questionnaires")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <path d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM8 5V8H4V5H8ZM4 14V10H8V14H4ZM4 16H8V19H4V16ZM10 16H20V19H10V16ZM20 14H10V10H20V14ZM20 5V8H10V5H20Z"></path>
                  </svg>
                  <CardHeader title={"Questionnaires"} subheader={""} />
                </div>
                {data?.questionnaire.length !== 0 && (
                  <svg
                    onClick={() => handleDrawerOpen("questionnaires")}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "28px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                  >
                    <path d="M10.071 4.92902L11.4852 6.34323L6.82834 11.0001L16.0002 11.0002L16.0002 13.0002L6.82839 13.0001L11.4852 17.6569L10.071 19.0712L2.99994 12.0001L10.071 4.92902ZM18.0001 19V5.00003H20.0001V19H18.0001Z"></path>
                  </svg>
                )}
              </div>{" "}
              {!data?.questionnaire || data?.questionnaire.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {data?.questionnaire.map((quest, index) => (
                    <div key={index} className="logos">
                      <h5>{quest.category}</h5>
                      {quest?.logo === "" ||
                      quest?.logo === undefined ||
                      quest?.logo === "undefined" ? (
                        <div
                          className="no-logo"
                          onClick={() =>
                            handleDrawerOpen("questionnaires", index)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="truncate"
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform = "scale(1.2)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          >
                            {quest.category}
                          </p>
                        </div>
                      ) : (
                        <img
                          className="audits-images"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_QUESTIONNAIRE_LOGO}/${quest?.logo}`}
                          alt="logo"
                          onClick={() =>
                            handleDrawerOpen("questionnaires", index)
                          }
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )}

                      <p className="link-circle">
                        {quest.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon medium="true" />
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>

          {/* Documents */}
          <Grid item xs={12} md={12} className="border-public">
            <Card sx={{ mb: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: "15px",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleDrawerOpen("documents")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <path d="M9 2.00318V2H19.9978C20.5513 2 21 2.45531 21 2.9918V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9 2.00318ZM5.82918 8H9V4.83086L5.82918 8ZM11 4V9C11 9.55228 10.5523 10 10 10H5V20H19V4H11Z"></path>
                  </svg>
                  <CardHeader title={"Documents"} subheader={""} />
                </div>
                {data?.documents.length !== 0 && (
                  <svg
                    onClick={() => handleDrawerOpen("documents")}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "28px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                  >
                    <path d="M10.071 4.92902L11.4852 6.34323L6.82834 11.0001L16.0002 11.0002L16.0002 13.0002L6.82839 13.0001L11.4852 17.6569L10.071 19.0712L2.99994 12.0001L10.071 4.92902ZM18.0001 19V5.00003H20.0001V19H18.0001Z"></path>
                  </svg>
                )}
              </div>{" "}
              {!data?.documents || data?.documents.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {data?.documents.map((doc, ind) => (
                    <div className="logos" key={ind}>
                      {/* <h5>{doc.category}</h5> */}
                      <h5>{truncateText(doc.category, 16, 3)}</h5>
                      {doc?.logo === "" ||
                      doc?.logo === undefined ||
                      doc?.logo === "undefined" ? (
                        <div
                          className="no-logo"
                          onClick={() => handleDrawerOpen("documents", ind)}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="truncate"
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform = "scale(1.2)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          >
                            {doc.title.split(" ").slice(0, 5).join(" ")}...
                          </p>
                        </div>
                      ) : (
                        <img
                          className="audits-images"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_DOCUMENTS_LOGO}/${doc?.logo}`}
                          alt="logo"
                          onClick={() => handleDrawerOpen("documents", ind)}
                          style={{ cursor: "pointer" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.2)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      )}
                      <p className="link-circle">
                        {doc.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon medium="true" />
                        )}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>

          {/* Other Info */}
          <Grid item xs={12} md={12} className="border-public">
            <Card sx={{ mb: 1 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginLeft: "15px",
                  marginRight: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handleDrawerOpen("otherInfo")}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "25px",
                      cursor: "pointer",
                    }}
                  >
                    <path d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11 11H13V17H11V11ZM11 7H13V9H11V7Z"></path>
                  </svg>
                  <CardHeader title={"Other Info"} subheader={""} />
                </div>
                {data?.categories.length !== 0 && (
                  <svg
                    onClick={() => handleDrawerOpen("otherInfo")}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    style={{
                      width: "28px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                  >
                    <path d="M10.071 4.92902L11.4852 6.34323L6.82834 11.0001L16.0002 11.0002L16.0002 13.0002L6.82839 13.0001L11.4852 17.6569L10.071 19.0712L2.99994 12.0001L10.071 4.92902ZM18.0001 19V5.00003H20.0001V19H18.0001Z"></path>
                  </svg>
                )}
              </div>{" "}
              {!data?.categories || data?.categories.length === 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p>No Record Found</p>
                  </div>
                </>
              ) : (
                <div className="d-flex-logo">
                  {data?.categories.map((cat, index) => (
                    <div key={index} className="logos">
                      <h5>{cat.category}</h5>
                      {cat?.logo === "" || cat?.logo === undefined ? (
                        <div
                          className="no-logo"
                          onClick={() => handleDrawerOpen("documents", index)}
                          style={{ cursor: "pointer" }}
                        >
                          <p
                            className="truncate"
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform = "scale(1.2)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform = "scale(1)")
                            }
                          >
                            {/* {doc.title.split(" ").slice(0, 5).join(" ")}... */}
                            {truncateText(cat.title, 5, 5)}
                          </p>
                        </div>
                      ) : (
                        <img
                          className="audits-images"
                          src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_CATEGORY_LOGO}/${cat?.logo}`}
                          alt="logo"
                          onClick={() => handleDrawerOpen("otherInfo", index)}
                          style={{ cursor: "pointer" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          }
                        />
                      )}
                      <p className="link-circle">
                        {/* {cat.type === "private" ? (
                          <LockIcon />
                        ) : (
                          <LockOpenIcon medium="true" />
                        )} */}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PublicPage;
