import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  CardHeader,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

const OverviewSection = (props) => {
  const theme = localStorage.getItem("theme");

  const { data, handleDrawerClose } = props;
  return (
    <Container
      maxWidth={false}
      className={theme === "Light" ? "light-mode-class" : "dark-mode-class"}
      style={{ paddingBottom: "18px" }}
    >
      <Box
        sx={{
          mt: 3,
          mb: 7,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          <span>Overview</span>
        </Typography>
        <ClearIcon onClick={handleDrawerClose} sx={{ cursor: "pointer" }} />
      </Box>
      <Paper elevation={3}>
        <Grid container spacing={3} sx={{ padding: 0 }}>
          <Grid item xs={12} md={12}>
            {/* <CheckCircleIcon color="success" sx={{ fontSize: 20 }}/> */}
            <CardHeader title={"Description"} subheader={""} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ paddingLeft: "16px", wordBreak: "auto-phrase" }}>
                {data.description}
              </p>
            </div>
          </Grid>
          <Grid item xs={8} md={8}>
            <CardHeader title={"Links"} subheader={""} />
            <div className="flex-policy">
              <div className="statics-links">
                {data.links.map((link, index) => (
                  <div key={index}>
                    <ul>
                      <li>
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            color:
                              localStorage.getItem("theme") === "Dark"
                                ? "white"
                                : "black",
                            wordBreak: "break-word",
                          }}
                        >
                          {link}
                        </a>
                      </li>
                    </ul>
                  </div>
                ))}
                {data.links.length === 0 && (
                  <p style={{ padding: "0" }}>No Links Available</p>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={4} md={4}>
            <div className="flex-policy">
              <div className="logo-image-policy" style={{ marginTop: "18px" }}>
                <img
                  className="profile-logo"
                  src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_THIRD_PARTY_PROFILE_LOGO}/${data.logo}`}
                  alt="logo"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default OverviewSection;
