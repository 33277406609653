import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

// layouts
import { DashboardLayout, DashboardLayoutAdmin } from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

// pages
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import AddFilePage from './pages/AddFilePage';
import RegistrationPage from './pages/RegistrationPage';
import TwoFaPage from './pages/TwoFaPage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ProfilePage from './pages/ProfilePage';
import VendorsPage from './pages/VendorsPage';
import VendorInformation from './pages/VendorInformation';
import VendorDetailsPage from './pages/VendorDetailsPage';
import ProtectedRoute from './components/gurad/ProtectedRoute.js';
import RequestUrlPage from './pages/RequestUrlPage';
import ConfirmAccountPage from './pages/ConfirmAccountPage';

import ThirdPartyDashboardPage from './pages/third-party/ThirdPartyDashboardPage.jsx';
import StructurePage from './pages/third-party/StructurePage.jsx';
import UploadTab from './pages/third-party/UploadTab';
import EditProfile from './pages/third-party/EditProfile';
import AddCompliance from './pages/third-party/AddCompliance';
import AddQuestionnaire from './pages/third-party/AddQuestionnaire';
import AddInfo from './pages/third-party/AddInfo';
import AddDocument from './pages/third-party/AddDocument';
import ConnectPublicURL from './pages/third-party/ConnectPublicURL';
import Requests from './pages/third-party/Requests';
import PublicPage from './pages/third-party/Public URL/PublicPage';
import ShowFile from './pages/third-party/ShowFile.js';
import AddQuestionnaireSet from './pages/third-party/AddQuestionnaireSet.jsx';
import UpdateQuestionnaireSet from './pages/third-party/UpdateQuestionnaireSet.jsx'
import Questionnaire from './pages/third-party/Questionniare.jsx';
import QuestionnaireSetAnswer from './pages/third-party/QuestionnaireSetAnswer.jsx';
import GetRequest from './components/apiConnections/getRequest';
import Loader from './Loader';
// ----------------------------------------------------------------------

function NotFoundUrl() {
  // 👇️ redirect to external URL
  window.location.replace(`${process.env.REACT_APP_NOT_FOUND_URL}`);

  return null;
}

export default function Router({ toggleTheme, theme, exceltheme }) {
  let isLoggedInThirdParty =
    localStorage.getItem("R4") && localStorage.getItem("roleId") === "4"
      ? true
      : false;

  const [publicPageData, setPublicPageData] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (window.location.href.includes("/public-page/")) {
      let uri = window.location.href.split("/public-page/");
      getPublicPageData(uri[1]);
    } else {
      getPublicPageData(window.location.host);
    }
  }, []);

  const getPublicPageData = async (publicUrlId) => {
    setLoader(true);

    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_THIRD_PARTY_PUBLIC_PAGE_DATA}?publicUrlId=${publicUrlId}`,
        {}
      )
        .then((res) => {
          if (res?.data?.code === 200) {
            const apiResponse = res?.data?.data; 
            setPublicPageData({
              publicUrlId,
              profileData: {
                companyName: apiResponse.profileDetails.companyName,
                description: apiResponse.profileDetails.description,
                logo: apiResponse.profileDetails.logo,
                links: apiResponse.profileDetails.link,
              },
              complianceCard: apiResponse.complianceCard,
              questionnaire: apiResponse.questionnaire,
              documents: apiResponse.documents,
              categories: apiResponse.categories,
            });
          }

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
    }
  };

  const routes = useRoutes(
    loader
      ? [
          {
            path: "*",
            element: <Loader show={true} />,
          },
        ]
      : publicPageData
      ? [
          {
            path: "*",
            element: (
              <PublicPage
                data={publicPageData}
                toggleTheme={() => toggleTheme()}
              />
            ),
          },
        ]
      : [
          {
            path: "/third-party",
            element: (
              <ProtectedRoute isLoggedIn={isLoggedInThirdParty}>
                <DashboardLayout
                  toggleTheme={() => toggleTheme()}
                  theme={theme}
                />
              </ProtectedRoute>
            ),
            children: [
              { path: "dashboard", element: <ThirdPartyDashboardPage /> },
              { path: "profile", element: <ProfilePage /> },
              { path: "change-password", element: <ChangePasswordPage /> },
              { path: "upload-tab", element: <UploadTab /> },
              { path: "add-compliance", element: <AddCompliance /> },
              { path: "add-questionnaire", element: <AddQuestionnaire /> },
              { path: "add-info", element: <AddInfo /> },
              { path: "add-document", element: <AddDocument /> },
              { path: "page-profile", element: <StructurePage /> },
              { path: "page-profile/edit", element: <EditProfile /> },
              { path: "public-url", element: <ConnectPublicURL /> },
              { path: "requests", element: <Requests /> },
              {
                path: "add-questionnaire-set",
                element: <AddQuestionnaireSet />,
              },
              {
                path: "edit-questionnaire-set",
                element: <UpdateQuestionnaireSet />,
              },
              { path: "questionnaire", element: <Questionnaire /> },
              {
                path: "questionnaire-set-answer",
                element: <QuestionnaireSetAnswer />,
              },
            ],
          },
          {
            path: "pgtioag",
            element: <RegistrationPage toggleTheme={() => toggleTheme()} />,
          },
          { path: "twofa", element: <TwoFaPage /> },
          { path: "forget-password", element: <ForgetPasswordPage toggleTheme={toggleTheme} /> },
          { path: "confirm-account/:token", element: <ConfirmAccountPage /> },
          { path: "reset-password/:token", element: <ResetPasswordPage /> },
          {
            path: "show-file",
            element: <ShowFile exceltheme={exceltheme} />,
          },
          {
            path: "public-page/:publicUrlId",
            element: (
              <PublicPage
                data={publicPageData}
                toggleTheme={() => toggleTheme()}
              />
            ),
          },
          { path: "*", element: <NotFoundUrl /> },
          {
            path: "/",
            element: <LoginPage toggleTheme={() => toggleTheme()} />,
          },
        ]
  );

  return routes;
}